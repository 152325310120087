import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pager from "../components/pager"

const Blog = ({ data, location, pageContext}) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title={data.site.siteMetadata.description} />
        <p className="fs--20">
          No se encontró ningún post.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={data.site.siteMetadata.description} />
      <div className="row">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <Link key={post.fields.slug} to={post.fields.slug} itemProp="url" className="col-12 col-md-6 mb-5 position-relative text-dark clearfix text-decoration-none">
              <figure className="d-block text-center overlay-dark-hover overlay-opacity-2 rounded overflow-hidden">
                <div className="text-center bg-light h--250 rounded bg-cover lazy"
                  data-loaded="true"
                  style={{ backgroundImage: `url(${post.frontmatter.featured_image})` }}
                ></div>
              </figure>

              <p className="mt-3 fs--20 mb-2">
                <span className="h6-xs text-dark d-block">
                  {title}
                </span>

                <span className="d-block text-muted font-regular fs--14">
                  Publicado: <time dateTime="{post.frontmatter.date}">{post.frontmatter.date}</time>
                </span>
              </p>
            </Link>
          )
        })}
      </div>
      <Pager pageContext={pageContext} />
    </Layout>
  )
}

export default Blog

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export const pagQuery = graphql`
  query ($skip: Int!, $limit: Int!){
    site {
      siteMetadata {
        title,
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(
            formatString: "DD MMMM YYYY",
            locale: "es-MX"
          )
          title
          description
          featured_image
        }
      }
    }
  }
`